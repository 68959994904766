import React from "react";
import styled from "styled-components";
import '../CSS/Loader.css';
// import logo from '../assets/Images/logo.png';

const Loader = () => {
  return (
    <StyledWrapper>
      <div className="loader">
        <div className="wrapper">
          <div className="circle" />
          <div className="line line-1" />
          <div className="line line-2" />
          <div className="line line-3" />
          <div className="line line-4" />
        </div>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;

  .loader {
    position: relative;
    width: 100%;
    max-width: 450px;
    height: 250px;
    border-radius: 12px;
    border: 1px solid #d3d3d3;
    padding: 20px;
    background-color: #f0f0f5;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .loader:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(110deg, rgba(240, 240, 245, 0) 0%, rgba(240, 240, 245, 0) 40%, rgba(240, 240, 245, 0.5) 50%, rgba(240, 240, 245, 0) 60%, rgba(240, 240, 245, 0) 100%);
    animation: gradient-animation 1s linear infinite;
  }

  .loader .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px 0;
  }

  .loader .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e0e0e5;
    margin-bottom: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .loader .line {
    background-color: #d0d0d5;
    border-radius: 4px;
  }

  .loader .line-1 {
    height: 10px;
    width: 80%;
    margin-left: 60px;
  }

  .loader .line-2 {
    height: 10px;
    width: 90%;
    margin-left: 60px;
  }

  .loader .line-3 {
    height: 10px;
    width: 100%;
  }

  .loader .line-4 {
    height: 10px;
    width: 92%;
  }

  @keyframes gradient-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export default Loader;
