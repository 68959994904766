import React, { useState, useEffect } from 'react';
import CleanPlace from '../assets/Images/CleanPlace.jpg';
import Logo from '../assets/Images/logo.png';
import FooterPage from '../components/FooterPage';
// import BackToTopButtom from '../components/BackToTopButtom';
import Request from '../components/Request';
import { RingLoader } from "react-spinners";
import SocialMedia from '../assets/Icons/social-media.gif';
import Email from '../assets/Icons/Email.gif';
import IncomingCall from '../assets/Icons/incoming-calls.gif';

const Contact = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <div className=" mx-auto overflow-hidden">
      {loading ? <div className="flex justify-center items-center h-screen">
        <RingLoader color={"#d4af37"} loading={loading} size={80} /></div> :
        <>
        <div className={`relative w-full h-96 `}>
          <img src={CleanPlace} className='absolute w-full h-96' alt='clean place ' />
          <div className=" w-full h-96 bg-black opacity-50"></div>
          <div className="absolute top-1/3 left-2/4 text-4xl font-bold my-10 text-[#ffffff] ">
            <div className='flex-flex justify-center'>
              <h1 className="text-5xl font-bold text-center my-8 flex justify-center">Contact Us</h1>
              <p className='text-xl text-center text-[#d4af37]'>Experience the art of immaculate living.</p>
            </div>
          </div>
        </div>
          <div className="flex  justify-center my-14">
            <h2 className="text-center text-[#d4af37] text-4xl font-bold mb-8 p-5">
              We are here to help you with all your cleaning needs.<br></br> Please contact us
              through any of the<br></br> methods below.
            </h2>
          </div>
          <div className='flex flex-col md:flex-row justify-center mt-5 bg-gradient-color'>
            <img src={Logo} className='w-full h-96 p-5' alt='Fabiana Fabulous Cleaning Services LLC' />
            <div className='flex-grow w-full'>
              <h2 className='text-start text-3xl text-[#057dcd] font-semibold p-2'>Fabiana Fabulous Cleaning Services LLC.</h2>
              <ul className='text-xl p-3 tracking-normal  '>
                <li className='p-2'><img src={IncomingCall} alt='phone' className='w-12 h-12' />
                  <a href='tel: 425-829-6092' className='text-[#057dcd] hover:text-[#d4af37] ml-2 ' title='Call Us'>
                    (425) 829-6092
                  </a>
                </li>
                  {/* <p className='text-start text-xl text-[#057dcd] font-semibold p-2'>Windows, Gutters, Pressure Washing, and Roof Cleaning Services Call.!</p>
                <li className='p-2'><img src={IncomingCall} alt='phone' className='w-12 h-12' />
                  <a href='tel: 206-617-0191' className='text-[#057dcd] hover:text-[#d4af37] ml-2 ' title='Call Us'>
                    (206) 617-0191
                  </a>
                </li> */}
                <li className='p-2 '><img src={Email} alt='email' className='w-12 h-12' />
                  <a href='mailto:fsandes73@gmail.com' className='text-[#057dcd] hover:text-[#d4af37] ml-2'>
                    fsandes73@gmail.com
                  </a>
                </li>
                <li className='p-2'><img src={SocialMedia} alt='social media' className='w-12 h-12' />Media:
                  <a href='https://www.facebook.com/Fabulouscleaner' className='text-[#057dcd] ml-2'>
                    Facebook
                  </a> , 
                  <a href='https://www.instagram.com/fabianafabulouscleaningservice/' className='text-[#d9042b] ml-2'>
                    Instagram
                  </a>
                </li>
              </ul>
              <p className='text-start text-xl font-semibold ml-2'>Operation Hours</p>
              <ul className='text-xl p-3 tracking-normal'>
                <li className='p-2'>Monday - Friday: 7:00am - 6:00pm</li>
                <li className='p-2'>Saturday: 7:00am - 1:00pm</li>
                <li className='p-2'>Sunday: Closed</li>
              </ul>
            </div>
          </div>
          <h1 className="text-4xl bg-gradient-colors text-[#ffffff] font-serif font-bold text-center  px-16 py-12 bloom-text">Refer us and get 15% off on your next cleaning!</h1>
          <Request />
          <FooterPage />
          {/* <div className='p-2'>
            <BackToTopButtom />
          </div> */}
        </>
      }
    </div>
  );
};

export default Contact;