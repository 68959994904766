import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import A_Clean from '../assets/Images/a-clean-house.jpg';
import Team from '../assets/Images/team.jpg';
import FooterPage from '../components/FooterPage';
// import BackToTopButton from '../components/BackToTopButtom';
import { RingLoader } from "react-spinners";

const About = () => {
  const [loading, setLoading] = useState(true);
  const [fade, setFade] = useState(false); // State for fade effect

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setFade(true); // Trigger fade effect
    }, 2000);
  }, []);

  const handleMenuItemClick = () => {
    setFade(false); // Reset fade effect
  };

  return (
    <div className="overflow-hidden">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
        <RingLoader color={"#d4af37"} loading={loading} size={80} fade={fade}/>
        </div>
      ) : (
        <>
          <div className={`relative w-full h-96 `}>
            <img src={A_Clean} className='w-full h-full object-cover' alt='A clean house' />
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 text-4xl font-bold my-10 text-[#ffffff]">
              <h1 className="text-5xl font-bold text-center my-8">About Us</h1>
            </div>
          </div>
          <h2 className='text-center text-4xl font-semibold p-2 m-10'>Who We Are!</h2>
          <div className='flex flex-col md:flex-row justify-center bg-gradient-color'>
            <img src={Team} className='w-full h-96 p-5 mt-10 object-cover' alt='Our cleaning team' />
            <div className='flex-grow w-full'>
              <h5 className='text-xl p-3 leading-loose first-letter:text-3xl'>
                We are a family-owned and operated cleaning service that provides professional cleaning services to residential and commercial
                clients in Eastside and Seattle, WA. <br></br>We offer a wide range of services to meet your cleaning needs,
                including home, deep cleaning, office, move-in/out, Airbnb, gutter, window, roof, pressure, and more.
                Our experienced cleaners are dedicated to providing high-quality service and exceptional results.
                We take pride in our work and strive to exceed our clients' expectations. We treat every job as our #1 priority.<br></br>
                We have been a professional cleaning service company since 1998, always offering the best quality or a money-back guarantee. <strong>Fabiana Fabulous Cleaning Services LLC </strong>
                provides incredible cleaning services and will strive to provide exceptional service.
                Our professionals use only the best tools and equipment to professionally clean your property at the best prices. Call us today to see what we can do for you!
              </h5>
            </div>
          </div>
          <div>
            <p className="flex justify-evenly text-center text-4xl font-bold px-1 py-6 my-4">Proudly Serving
              <Link
                className="mt-1 block px-2 py-1 text-xl font-semibold rounded text-[#d4af37] sm:mt-0 sm:ml-2 transition duration-300 cursor-pointer"
                to="/request"
                smooth={true}
                duration={500}
                onClick={handleMenuItemClick}
              >
                Book Now!
              </Link>
            </p>
            <p className='text-center text-xl font-bold px-1 py-6'>Bellevue | Redmond | Sammamish | Woodinville | Kirkland | Seattle | Lake Stevens | Edmonds</p>
          </div>
          <FooterPage />
          {/* <div className='p-2'>
            <BackToTopButton />
          </div> */}
        </>
      )}
    </div>
  );
};

export default About;
