import React, { useState, useEffect } from 'react';
import CleanHome from '../assets/Images/CleanHome.jpg';
import Clean from '../assets/Images/clean.jpg';
import Office from '../assets/Images/office.jpeg';
import Roof from '../assets/Images/Roof-half.jpg';
import Window from '../assets/Images/Window.jpg';
import Gutter from '../assets/Images/gutter-cleaning.jpg';
import Driveway from '../assets/Images/driveway.jpg';
// import CleanPlace from '../assets/Images/CleanPlace.jpg';
import Logo from '../assets/Images/logo.png';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import FooterPage from '../components/FooterPage';
// import BackToTopButtom from '../components/BackToTopButtom';
import Request from '../components/Request';
import RingLoader from "react-spinners/RingLoader";





const Services = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);


  return (
    <div className="">
      {loading ? <div className="flex justify-center items-center h-screen">
        <RingLoader color={"#d4af37"} loading={loading} size={80} /></div> :
        <>
          <div className={`relative w-full h-96 `}>
          <img src={CleanHome} className=' absolute w-full h-96' alt='clean home' />
          <div className=" w-full h-96 bg-black opacity-50"></div>
          <div className="md:absolute md:top-1/3 md:left-2/4 text-4xl font-bold my-10 text-[#ffffff] 
        absolute top-1/3 ">
            <div className='flex-flex justify-center'>
              <h1 className="text-5xl font-bold text-center my-8 flex justify-center font-serif ">Our Services</h1>
              <h6 className='text-xl text-center text-[#d4af37] ml-5'>Experience the art of immaculate living.</h6>
            </div>
          </div>
        </div>
        <h3 className='text-4xl font-bold text-center my-10 flex justify-center font-serif'> We provide a wide range of services.</h3>
          <div className='flex flex-col md:flex-row justify-center my-5'>
            <img src={Clean} className='w-full h-96 p-5' alt='clean house' />
            <div className='flex-grow w-full'>
              <h2 className='text-start text-3xl font-semibold p-2 font-serif '>Home Cleaning</h2>
              <p className='text-xl p-3 tracking-normal leading-loose '>
                If you are looking for a reliable team of cleaners to tidy up your home, <strong>Fabiana Fabulous Cleaning Services LLC </strong>
                is your choice! Are you looking for a trustworthy team of cleaners to tidy up your home?
                Look no further than Fabiana Fabulous Cleaning Services LLC!
                Our professional cleaners will arrive at your home and clean every room, living space, kitchen,
                and bathroom for an affordable price. We promise to leave your place looking better than ever!
                Cleaners will arrive onsite and clean every room, living space, kitchen, and bathroom for a great price.
                We promise that we will have your place looking better than before!</p>
              <button
                className='flex items-center ml-2 bg-blue-500 hover:bg-gradient-colors text-white  font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline'
                type='submit'>
                <UilCalendarAlt className="mr-2 button-animate" size="20" />
                <a href="tel:425-829-6092" className='hover:text-[#d4af37]' >
                  Schedule Today
                </a>
              </button>
            </div>
          </div>
          <div className='flex flex-col md:flex-row justify-center my-12 bg-gradient-color'>
            <div className='flex-grow w-full'>
              <h2 className='text-end text-3xl font-semibold p-2 font-serif '>Office Cleaning</h2>
              <p className='text-lg p-3 text-end leading-loose '>
                <strong>Fabiana Fabulous Cleaning Services LLC </strong>offers incredible floor and office Fabiana Fabulous Cleaning Services LLC
                provides exceptional cleaning services for offices and floors,
                catering to the needs of floor and office managers who wish to maintain a clean and hygienic
                environment for their employees and customers. We leave no stone unturned in our quest to ensure that every meeting room,
                waiting room, lobby, workspace, bathroom, and floor is spotless.
                Our team of experts is always at your disposal - call us today to speak with one of our crew!
                cleaning services for floor and office managers looking to clean their space for their employees and customers.
                We will not leave a single meeting room, waiting room, lobby, workspace,
                bathroom, or floor unattended to - call us today to speak with one of our crew!</p>
              <div className='flex justify-end m-1 p-1'>
                <button
                  className='flex items-center ml-2 bg-blue-500 hover:bg-gradient-colors text-white hover:text-[#d4af37] font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline'
                  type='submit'>
                  <UilCalendarAlt className="mr-2 button-animate" size="20" />
                  <a href="tel:425-829-6092" className='hover:text-[#d4af37]' >
                    Schedule Today
                  </a>
                </button>
              </div>
            </div>
            <img src={Office} className='w-full h-96 p-5' alt='office' />
          </div>
          {/* <div className='flex flex-col md:flex-row justify-center my-12'>
            <img src={Driveway} className='w-full h-96 p-5' alt='driveway' />
            <div className='flex-grow w-full'>
              <h2 className='text-start text-3xl font-semibold p-2 font-serif '>Pressure Washing</h2>
              <p className='text-lg p-3 leading-loose '>
                At <strong>Fabiana Fabulous Cleaning Services LLC,</strong> we offer pressure washing services to help you remove any unwanted dirt,
                stains, or build-up on your property.
                Our team can efficiently remove such stubborn marks from your walls, driveways,
                and floors at an affordable price. Feel free to give us a call today to discuss your specific needs!
                At Fabiana Fabulous Cleaning Services LLC, our pressure washing service is
                specifically designed to rid your property of any unwanted or stubborn build-up, dirt, stains, or splotches.
                We can remove such errors from walls, floors, and driveways
                for an incredible price—call us today to discuss your needs!</p>
              <button
                className='flex items-center ml-2 bg-blue-500 hover:bg-gradient-colors text-white hover:text-[#d4af37] font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline'
                type='submit'>
                <UilCalendarAlt className="mr-2 button-animate" size="20" />
                <a href="tel:206-617-0191" className='hover:text-[#d4af37]' >
                  Schedule Today
                </a>
              </button>
            </div>
          </div>
          <div className='flex flex-col md:flex-row justify-center my-12 bg-gradient-color'>
            <div className='flex-grow w-full'>
              <h2 className='text-end text-3xl font-semibold p-2 font-serif '>Roof Cleaning</h2>
              <p className='text-lg p-3 text-end leading-loose'>
                Are you searching for trustworthy roof cleaners? Look no further than <strong>Fabiana Fabulous Cleaning Services LLC. </strong>
                Regular roof cleaning helps to preserve its durability and prolong its lifespan.
                Algae and moss, in particular, can trap moisture on the roof surface,
                leading to decay in wooden roof elements and corrosion in metal ones.
                If you are looking for reliable roof cleaners, Fabiana Fabulous Cleaning Services LLC
                is your choice! One of the primary reasons for cleaning a roof is to maintain its integrity and
                extend its lifespan. Algae and moss, in particular, can hold moisture against the roof surface,
                leading to rot in wooden roof components and corrosion in metal ones.
              </p>
              <div className='flex justify-end m-1 p-1'>
                <button
                  className='flex items-center ml-2 bg-blue-500 hover:bg-gradient-colors text-white hover:text-[#d4af37] font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline'
                  type='submit'>
                  <UilCalendarAlt className="mr-2 button-animate" size="20" />
                  <a href="tel:206-617-0191" className='hover:text-[#d4af37]'>
                    Schedule Today
                  </a>
                </button>
              </div>
            </div>
            <img src={Roof} className='w-full h-96 p-5' alt='Roof' />
          </div>
          <div className='flex flex-col md:flex-rowjustify-center my-12'>
            <img src={Gutter} className='w-full h-96 p-5' alt='gutter' />
            <div className='flex-grow w-full'>
              <h2 className='text-start text-3xl font-semibold p-2 font-serif '>Gutter Cleaning</h2>
              <p className='text-lg p-3 leading-loose'>
                If you are looking for reliable gutter cleaners, <strong>Fabiana Fabulous Cleaning Services LLC </strong> is your choice!
                Gutter cleaning is a vital maintenance task for any home,
                often underestimated in its importance. Gutters,
                designed to control the flow of rainwater to protect your roof, walls,
                foundation, and landscape, can become clogged with leaves, twigs, and other debris.
                When this happens, they fail to function correctly. If you need reliable gutter cleaners,
                look no further than Fabiana Fabulous Cleaning Services LLC.
                Gutter cleaning is a crucial maintenance task for any home, often underestimated in its importance.
                However, they can become clogged with leaves, twigs, and other debris,
                causing them to malfunction and leading to many potential problems. </p>
              <button
                className='flex items-center ml-2 bg-blue-500 hover:bg-gradient-colors text-white hover:text-[#d4af37] font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline'
                type='submit'>
                <UilCalendarAlt className="mr-2 button-animate" size="20" />
                <a href="tel:206-617-0191" className='hover:text-[#d4af37]'>
                  Schedule Today
                </a>
              </button>
            </div>
          </div>
          <div className='flex flex-col md:flex-row justify-center mt-12 bg-gradient-color'>
            <div className='md:flex-grow md:w-full '>
              <h2 className='text-end text-3xl font-semibold p-2 font-serif '>Window Cleaning</h2>
              <p className='text-lg p-3 text-end leading-loose '>When it comes to window cleaning,
                no one does it better than<strong> Fabiana Fabulous Cleaning Services LLC. </strong>
                Our team uses the best cleaning materials available to clean the windows of your commercial or residential property!
                We will have your windows cleaned in no time - call us today to schedule your first appointment!</p>
              <div className='flex justify-end m-1 p-1'>
                <button
                  className='flex items-center ml-2 bg-blue-500 hover:bg-gradient-colors text-white hover:text-[#d4af37] font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline'
                  type='submit'>
                  <UilCalendarAlt className="mr-2 button-animate" size="20" />
                  <a href="tel:206-617-0191" className='hover:text-[#d4af37]' >
                    Schedule Today
                  </a>
                </button>
              </div>
            </div>
            <img src={Window} className='w-full h-96 p-5' alt='window' />
          </div> */}
          <div className="relative">
            {/* Background Image */}
            <img src={CleanHome} className="w-full h-64 md:h-96" alt="clean home" />

            {/* Dark Overlay */}
            <div className="absolute inset-0 bg-black opacity-75"></div>

            {/* Logo - Stacked on small screens */}
            <img
              src={Logo}
              className="absolute top-8 left-24 transform -translate-x-1/2 md:top-1/2 md:left-1/4 md:-translate-y-1/2 w-40 h-40 md:w-64 md:h-64"
              alt="Fabiana Fabulous Cleaning Services LLC"
            />

            {/* Slogan - Positioned below the logo on small screens */}
            <h5 className="absolute top-12 left-2/3 transform -translate-x-1/3 text-[#d4af37] text-xl md:text-xl md:top-1/2 md:left-2/3 md:-translate-y-1/2 text-center">
              WE MAKE YOUR HOME LOOK LIKE NEW AGAIN
            </h5>

            {/* Button - Positioned below the slogan on small screens */}
            <div className="absolute top-48 left-1/2 transform -translate-x-1/2 md:top-2/3 md:left-2/3 md:-translate-y-1/2 flex items-center bg-[#43b0f1] hover:bg-gradient-colors px-4 py-2 rounded-md">
              <button

                className="text-white flex items-center text-base md:text-lg hover:text-[#d4af37]"
              >
                <UilCalendarAlt className="mr-2 button-animate " size="18" />  <a href="tel:425-829-6092" >
                  Schedule Today
                </a>
              </button>
            </div>
          </div>

          <Request />
          <FooterPage />
          {/* <div className='p-2'>
            <BackToTopButtom />
          </div> */}
        </>
      }
    </div>
  );
};

export default Services;