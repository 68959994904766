import React, { useState, useEffect } from 'react';
import Logo from '../assets/Images/logo.png';
import axios from 'axios';
import { motion } from 'framer-motion';
// import { Link } from 'react-router-dom';
import { UilCommentAltDots, UilTimesCircle } from '@iconscout/react-unicons';
import '../CSS/ChatNow.css';

const ChatNow = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasMounted, setHasMounted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showNotification, setShowNotification] = useState(true);
    const [isAgreed, setIsAgreed] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        projectDescription: ''
    });

    // Helper function to check active hours (7 AM to 6 PM)
    const isWithinActiveHours = () => {
        const now = new Date();
        const currentHour = now.getHours();
        return currentHour >= 7 && currentHour < 18;
    };

    useEffect(() => {
        const timeout = setTimeout(() => setHasMounted(true), 0);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        setShowNotification(isWithinActiveHours());
        const interval = setInterval(() => {
            setShowNotification(isWithinActiveHours());
        }, 3600000); // Check every hour
        return () => clearInterval(interval);
    }, []);

    // Automatically open the chat after 20 seconds if not engaged, only once per session
    useEffect(() => {
        if (!sessionStorage.getItem('chatAutoOpened')) {
            const timer = setTimeout(() => {
                if (!isOpen) {
                    setIsOpen(true);
                    setShowNotification(false);
                    sessionStorage.setItem('chatAutoOpened', 'true');
                }
            }, 12000); // 20 seconds

            // Clear timer if user opens chat manually
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    const toggleChat = () => {
        setIsOpen((prev) => {
            if (!prev) {
                sessionStorage.setItem('chatAutoOpened', 'true');
            }
            return !prev;
        });
        setShowNotification(false);
    };
    

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleCheckboxChange = (e) => {
        setIsAgreed(e.target.checked);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!formData.name || !formData.phone || !formData.email || !formData.projectDescription) {
            alert('Please fill out all fields');
            return;
        }
        setIsLoading(true);
        try {
            await axios.post('https://us-central1-fabicleaners-28c5e.cloudfunctions.net/sendsms', formData);
            setIsSubmitted(true);
            setFormData({ name: '', phone: '', email: '', projectDescription: '' });
            setIsAgreed(false); // Reset the checkbox after successful submission
        } catch (error) {
            console.error(error);
            alert('Failed to send message');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="chatnow__container">
            <div className="relative">
                <motion.button
                    whileHover={{ scale: 1.1 }}
                    className="chatnow__button"
                    onClick={toggleChat}
                    aria-expanded={isOpen}
                    aria-label={isOpen ? "Close Chat" : "Open Chat"}
                >
                    {isOpen ? (
                        <UilTimesCircle className="text-white w-7 h-7" />
                    ) : (
                        <UilCommentAltDots className="text-white w-9 h-9" />
                    )}
                    {showNotification && <span className="notification-dot">1</span>}
                </motion.button>
            </div>

            <div className={`chatnow__form-container ${hasMounted && (isOpen ? 'is-visible' : 'is-hidden')}`}>
                <div className="chatnow__logo">
                    <img src={Logo} alt="Fabiana Fabulous Cleaning Services LLC" />
                    <p className="text-sm">Have a question? Enter your info below, and we’ll SMS you soon.</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Name" required className="chatnow__input" />
                    <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone" required className="chatnow__input" />
                    <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" required className="chatnow__input" />
                    <textarea name="projectDescription" value={formData.projectDescription} onChange={handleChange} placeholder="Job Description" required className="chatnow__textarea" />
                    <div>
                        <h4 className="text-start font-bold p-1">Consent</h4>
                        <p className="text-xs border border-[#3b6bb4] p-2">
                            By checking the box and clicking SUBMIT below, you’re giving us permission to communicate with you via a call, text or email. We promise to only communicate when necessary for things regarding your quote request, reminders or an occasional special offer. No purchase is required. Plus, if you ever change your mind and you no longer want us to contact you, reply END or STOP to any email or text message to immediately opt out. Standard text rates apply.
                        </p>
                    </div>
                    <label className="flex items-center  text-sm mb-3">
                        <input
                            type="checkbox"
                            checked={isAgreed}
                            onChange={handleCheckboxChange}
                            className="mr-2 "
                        />
                        I agree to <a href="/privacy" className="hover:underline ml-2 text-red-600">Privacy-Policy</a>.
                    </label>
                    <button type="submit" disabled={isLoading} className="chatnow__submit-btn">
                        {isLoading ? 'Sending...' : 'Submit'}
                    </button>
                </form>
                {isSubmitted && <p className="chatnow__success-msg">Message sent successfully!</p>}
            </div>
        </div>
    );
};

export default ChatNow;