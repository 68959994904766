import React, { useState, useEffect } from 'react';
import Faby from '../assets/Icons/faby.webp';
import { RingLoader } from 'react-spinners';
import BackToTopButtom from './BackToTopButtom';
import FooterPage from './FooterPage';

const Section = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleSection = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="mb-4">
            <div className="flex justify-between items-center cursor-pointer bg-gray-200 p-4 rounded" onClick={toggleSection}>
                <h2 className="text-xl font-semibold text-[#003366] hover:text-[#d4af37]">{title}</h2>
                <span className="text-xl font-semibold">{isOpen ? '-' : '+'}</span>
            </div>
            {isOpen && <h5 className="p-4 bg-gray-100 rounded">{children}</h5>}
        </div>
    );
};

const TermsAndConditions = () => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);
    return (
        <div className="max-w-5xl mx-auto p-8 bg-white shadow-md rounded-lg">
            {loading ? <div className="flex justify-center items-center h-screen">
                <RingLoader color={"#d4af37"} loading={loading} size={80} /></div> :
                <>
                    <div className="relative w-full h-96">
                        <img src={Faby} className="absolute w-full h-96 object-cover" alt="clean place" />
                        <div className="absolute inset-0 bg-black opacity-50"></div>
                        <div className="absolute inset-0 flex items-center justify-center">
                            <h1 className="text-5xl font-bold text-center ">Terms & Conditions</h1>
                        </div>
                    </div>
                    <h2 className="m-10 py-5 text-2xl text-center text-[#0c0c0c]">
                        Welcome to our website. By using this website, you agree to comply with the terms and conditions of Fabiana Fabulous Cleaning Services LLC. If you disagree with any part, please refrain from using our website.
                    </h2>

                    <Section title="General Terms">
                        Fabiana Fabulous Cleaning Services LLC, also referred to as “Company” or “we”, reserves the right to amend these terms and conditions for our cleaning service in Washington State or outside Washington State without prior notice. By requesting service from Fabiana Fabulous Cleaning Services LLC, the client accepts these terms and agrees to abide by them.
                    </Section>

                    <Section title="COVID-19 Policy">
                        No customer shall book a service with Fabiana Fabulous Cleaning Services LLC without fully disclosing their knowledge of COVID-19 symptoms. It is the customer’s responsibility to inform the company of any symptoms. If you have recently traveled, been sick, or cared for someone who is sick, please disclose this information.
                    </Section>

                    <Section title="Refunds">
                        Our services are personalized; therefore, we do not offer refunds. However, if you have complaints about your cleaning service, contact us within 24 hours of the booking start time. We may offer a free re-clean under certain conditions.
                    </Section>

                    <Section title="Receipts After Cleaning">
                        Invoice are send it If the customer request one.
                    </Section>

                    <Section title="No Terms or Contract">
                        There is no fixed term to this agreement. Customers may cancel their recurring or one-time cleaning service with at least 24 hours’ notice. A $99 cancellation fee applies for cancellations made less than 24 hours before the scheduled booking.
                    </Section>

                    <Section title="Move-Out Cleaning">
                        Homes for move-out cleaning should be empty and free of clutter. Extra charges may apply if items are left in the fridge or cabinets requiring additional cleaning.
                    </Section>

                    <Section title="Hourly Services">
                        Hourly services are billed based on the current rate. Customers cannot change the booking type after service has begun without notifying Fabiana Fabulous Cleaning Services LLC.
                    </Section>

                    <Section title="Cleaning Approvals">
                        Customers may confirm satisfaction via voice, email, or text. Once received, requests for re-clean may be denied. Ensure all communications are acknowledged by Fabiana Fabulous Cleaning Services LLC within 24 hours of the initial clean.
                    </Section>

                    <Section title="Teams and Conduct">
                        Cleaners work in teams based on job size. Team members are instructed to perform tasks within their cleaning scope, respecting customer property and maintaining professionalism at all times.
                    </Section>

                    <Section title="Arrival Time">
                        Scheduled arrival times have a one-hour window to accommodate any traffic or unexpected delays.
                    </Section>

                    <Section title="Scheduling and Pricing Adjustments">
                        Prices for recurring services may vary based on frequency. Rescheduling a cleaning may result in a price adjustment.
                    </Section>

                    <Section title="Working at Heights">
                        Cleaners will not work at heights above two ladder rungs to maintain safety protocols.
                    </Section>

                    <Section title="Payment Policy and Cancellations">
                        Payment is due after the team is done. Accepted payment methods include Cash App, Zelle, Venmo, Pay Pal, and cash.
                    </Section>

                    <Section title="Late Cancellations and Rescheduling">
                        Late cancellation fee of $ half price of the house applies if cancellation happens. Rescheduling is free when arranged with more than 24 hours’ notice.
                    </Section>

                    <Section title="Lockouts and Inaccessibility">
                        If a cleaner cannot access the property, a $79 fee will be applied. For homes outside city limits, additional travel fees may apply.
                    </Section>

                    <Section title="Accidents and Theft">
                        The client is responsible for securing valuables. Any damages must be reported within 24 hours of service.
                    </Section>

                    <Section title="Pets">
                        Customers with pets should secure them and pick up after them. Cleaners do not clean litter boxes or animal waste.
                    </Section>

                    <Section title="Health and Safety">
                        Fabiana Fabulous Cleaning Services LLC reserves the right to refuse work that could compromise health and safety.
                    </Section>

                    <Section title="Quality Control">
                        Our quality control includes inspections and client contact to ensure satisfaction.
                    </Section>

                    <Section title="Before and After Photos">
                        We take before and after photos for quality assurance, which may be used for marketing purposes without revealing customer information.
                    </Section>

                    <Section title="Items We Cannot Clean">
                        Certain items, such as body fluids, pet accidents, or seasonal insect infestations, are outside our scope of cleaning services.
                    </Section>

                    <Section title="Hiring of Fabianan Fabulous Cleaning Services LLC Team Members">
                        Clients may not hire past or present team members directly. Violation of this clause incurs a placement fee.
                    </Section>

                    <Section title="Tips">
                        Tips are appreciated but not required. A recommended tip is 10-15% of the cleaning fee, and all tips are given fully to the cleaning team.
                    </Section>

                    <Section title="Disclaimer of Warranties">
                        Services are provided “as is” without warranty. The company disclaims all representations to the fullest extent permissible by law.
                    </Section>

                    <Section title="Limitation of Liability">
                        The company’s liability is limited to the amount paid by the customer in the last six months before the claim.
                    </Section>

                    <Section title="Termination">
                        The company reserves the right to modify, suspend, or terminate the service at any time.
                    </Section>

                    <Section title="Legal Disputes">
                        Disputes are governed by the laws of Washington State and resolved through binding arbitration.
                    </Section>

                    <p className="m-12 text-center">
                        By using this website and booking a service, you agree to the terms and conditions outlined above.
                    </p>
                    <FooterPage />
                    <div className='p-2'>
                        <BackToTopButtom />
                    </div>
                </>
            }
        </div>
    );
};

export default TermsAndConditions;
